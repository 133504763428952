import { createTransparentColor } from '../create-transparent-color';
import type { ColorDefinition } from './color-types';

const ClassicRedPalette = {
  Paper: '#eff0e9',

  // Primary Colors
  Red: '#cc0000',
  RedLight: '#990000',
  RedHighlight: '#e60000',

  // Alternate Colors
  Yellow: '#ffce2e',
  YellowLight: '#fac000',
  YellowHighlight: '#ffd447',

  // Neutral Colors
  Black: '#222829',
  Dark: '#4b5253',
  Darker: '#343b3c',
  Golden: '#dfc46a',
  Light: '#e0e4e3',
  Lighter: '#c7cbcb',
  Neutral: '#929797',
  White: '#ffffff',

  // Extra
  Aux: '#cc0000',
  Green: '#59a82e',
  LuzernGold: '#cd9b27',
};

const Primitive: ColorDefinition['Primitive'] = {
  Primary: ClassicRedPalette.Red,
  PrimaryTint: ClassicRedPalette.RedLight,
  PrimaryContrast: ClassicRedPalette.White,

  Secondary: ClassicRedPalette.Yellow,
  SecondaryTint: ClassicRedPalette.YellowLight,
  SecondaryContrast: ClassicRedPalette.Black,

  Accent: ClassicRedPalette.Red,
  AccentTint: ClassicRedPalette.RedLight,
  AccentContrast: ClassicRedPalette.White,

  Gradient: ClassicRedPalette.Red,
  GradientTint: ClassicRedPalette.RedLight,
  GradientContrast: ClassicRedPalette.White,
};

const TextColors: ColorDefinition['TextColors'] = {
  HeadingText: ClassicRedPalette.Black,
  BodyText: ClassicRedPalette.Dark,
  MutedText: ClassicRedPalette.Neutral,
  HighlightedText: ClassicRedPalette.Black,
  LinkText: ClassicRedPalette.Red,
  ErrorText: ClassicRedPalette.Red,
  DisabledText: '#AFB3B3',
};

const Surface: ColorDefinition['Surface'] = {
  Base: {
    Background: ClassicRedPalette.White,
    Foreground: ClassicRedPalette.Black,
    Dimmed: ClassicRedPalette.Light,
  },
  Nested: {
    Background: '#f0f3f3',
    Foreground: ClassicRedPalette.Black,
    Dimmed: ClassicRedPalette.Light,
  },
  Disabled: {
    Background: ClassicRedPalette.Light,
    Foreground: ClassicRedPalette.Neutral,
    Dimmed: ClassicRedPalette.Light,
  },
  Floating: {
    Background: ClassicRedPalette.White,
    Foreground: ClassicRedPalette.Black,
    Dimmed: ClassicRedPalette.Light,
  },
};

const Signal: ColorDefinition['Signal'] = {
  Success: ClassicRedPalette.Green,
  SuccessContrast: ClassicRedPalette.White,

  Info: ClassicRedPalette.Black,
  InfoContrast: ClassicRedPalette.White,

  Attention: ClassicRedPalette.Red,
  AttentionContrast: ClassicRedPalette.White,

  Danger: ClassicRedPalette.Yellow,
  DangerContrast: ClassicRedPalette.Black,
};

const CornerRadius: ColorDefinition['CornerRadius'] = {
  Small: '4px',
  Base: '12px',
  Large: '32px',
};

const Elevation: ColorDefinition['Elevation'] = {
  Level1: `0 3px 5px var(--shadow-color, ${createTransparentColor(ClassicRedPalette.Black, 0.15)})`,
  Level2: '0 4px 8px var(--shadow-color, rgba(0, 0, 0, 0.15))',
  Level3: '0 8px 12px var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: ColorDefinition['General'] = {
  Bonus: ClassicRedPalette.Red,
  BonusContrast: ClassicRedPalette.White,
};

const Layout: ColorDefinition['Layout'] = {
  Background: ClassicRedPalette.Paper,
  BrandBorder: Primitive.Primary,
  BrowserTheme: ClassicRedPalette.Paper,
  LoadingColor: Primitive.Primary,
  Overlay: ClassicRedPalette.White + 'CC',
  Sidebar: {
    Background: ClassicRedPalette.Darker,
    Foreground: ClassicRedPalette.White,
  },
};

const Hero: ColorDefinition['Hero'] = {
  Heading: ClassicRedPalette.White,
  Text: ClassicRedPalette.White,
  TextHighlight: ClassicRedPalette.Golden,

  Button: {
    Solid: '#d4af37',
    Rest: '#d4af37',
    Hover: ClassicRedPalette.Golden,
    Active: ClassicRedPalette.Golden,
    Text: Primitive.SecondaryContrast,
  },
};

export const Color: ColorDefinition = {
  TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,
};
